import React from "react";

const Category = () => {
  return (
    <div>
      <h2>Category</h2>
    </div>
  )
}

export default Category;
